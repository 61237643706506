.card-status {
  margin-top: 1.25em;
  display: flex;
  justify-content: center;
}

.add-session-btn {
  color: white !important;
  margin-left: 8px !important;
}

.container-card {
  padding: 1.25em;
  border-radius: 1.5em;
  box-shadow: 0 0.5em 1em rgba(38, 38, 38, 0.362);
  width: 100%;
  max-width: 50em;
  margin: 0 auto;

  .header {
    margin-bottom: 1.875em;

    .search-and-add {
      display: flex;
      align-items: center;

      .search-bar {
        flex-grow: 1;

        input {
          outline: none;
          box-shadow: none;
        }
      }

      .add-session-btn {
        padding: 0;
        margin: 0;
      }
    }
  }

  .card {
    border-radius: 1.5em;
    width: 100%;
    position: relative;
    border: none;
    margin-bottom: 1.25em;
    // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 0.3125em;
      background-color: transparent;
    }

    .profile-img {
      border-radius: 50%;
      margin: 0 auto;
      display: block;
      width: 3em;
      height: auto;
    }

    .status-circle {
      width: 1.25em;
      height: 1.25em;
      border-radius: 50%;
      margin-left: 0;
      vertical-align: middle;
      background-color: green;

      &.not-working {
        background-color: red;
      }
    }

    .btn-menu {
      background-color: transparent;
      border: none;
      color: black;
      top: 0;
      right: 0;
      cursor: pointer;

      img {
        width: 1.5625em;
        height: 1.5625em;
      }

      .dropdown-menu {
        max-width: 3.125em;
        max-height: 3.125em;
      }
    }

    .card-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }

  .d-flex.justify-content-center.align-items-center {
    padding: 0;
  }
}

.scrollable-container {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.header-text {
  font-size: 22px;
  font-weight: 700;
  width: 100%;
  margin-left: 15px;
  color: #414042;
}

.highlight-text {
  color: #92EF8F;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.add-session-btn-big {
  border-radius: 8%!important;
  background-color: #4C3699!important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15em;
}

.div-qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 12em;
}

.text-qrcode {
  font-size: 15px;
}

.qrcode-icon {
  width: 80px;
  height: 80px;
}

.separator {
  margin: 20px;
  border-left: 2px solid #dee2e6;
  height: 1px;
  opacity: 10%;
  background: black;
  width: 100%;
}

.session-message {
  color: #6c757d;
  font-size: 16px;
  margin-left: 20px;
  text-align: center;
}

.help-link {
  text-align: center;
  margin-top: 20px;
  color: #007bff;
  font-size: 14px;
  cursor: pointer;
}

.sessions-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.div-area {
  display: flex;
  justify-content: center;
  align-content: center;
}

/* Media queries */

@media (max-width: 75em) {
  .container-card {
    padding: 0.9375em;
  }

  .add-session-btn-big {
    width: 11em;
  }

  .div-qrcode {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 9em;
  }

  .qrcode-icon {
    width: 50px;
    height: 50px;
  }

  .text-qrcode {
    font-size: 12px;
  }
}

@media (max-width: 62em) {
  .container-card {
    padding: 0.625em;
  }

  .add-session-btn-big {
    width: 11em;
  }

  .div-qrcode {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 9em;
  }

  .qrcode-icon {
    width: 50px;
    height: 50px;
  }

  .text-qrcode {
    font-size: 12px;
  }
}

@media (max-width: 48em) {
  .container-card {
    max-width: 90%;
    padding: 0.3125em;
    margin: 0 auto;
  }

  .header {
    .search-and-add {
      flex-direction: column;
      align-items: flex-start;

      .search-bar {
        width: 100%;
        margin-bottom: 0.625em;
      }

      .add-session-btn {
        width: 100%;
        text-align: center;
      }
    }
  }

  .add-session-btn-big {
    width: 11em;
  }

  .div-qrcode {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 9em;
  }

  .qrcode-icon {
    width: 50px;
    height: 50px;
  }

  .text-qrcode {
    font-size: 12px;
  }
}

@media (max-width: 36em) {
  .container-card {
    padding: 0;
    max-width: 90%;
  }

  .card {
    border-radius: 0;
    margin-bottom: 0.625em;
  }

  .profile-img {
    width: 3.125em;
    height: 3.125em;
  }

  .add-session-btn-big {
    width: 11em;
  }

  .div-qrcode {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 9em;
  }

  .qrcode-icon {
    width: 50px;
    height: 50px;
  }

  .text-qrcode {
    font-size: 12px;
  }
}
