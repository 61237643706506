// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
}

.card-drop {
  color: var(--#{$prefix}body-color);
  width: 150px;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $gray-600;
  margin-bottom: 24px;  
}

.max-card-area {
  z-index: 2;
  position: relative;
}

/* Media queries */
@media (max-width: 75em) {
  .max-card-area {
  }
}

@media (max-width: 62em) {
  .big-card {
    margin-bottom: 13em;
  }

  .max-card-area {
    height: 100vh;
    overflow-y: auto;
  }
}

@media (max-width: 48em) {
  .big-card {
    margin-bottom: 13em;
  }

  .max-card-area {
    height: 100vh;
    overflow-y: auto;
  }
}

@media (max-width: 36em) {
  .big-card {
    margin-bottom: 13em;
  }

  .max-card-area {
    height: 100vh;
    overflow-y: auto;
  }
}