.auth-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: 
      radial-gradient(circle at top right, #7956F7 0%, transparent 60%) no-repeat,
      radial-gradient(circle at bottom left, #7956F7 0%, transparent 60%) no-repeat,
      linear-gradient(145deg, #36276F, #4C3699 50%, #36276F);
  background-size: 100% 100%;
  position: relative;
}


.authentication-page-content {
    background: #FFFFFF;
    border-radius: 1em;
    margin: 1.25em 0;
    box-shadow: 0 0.25em 0.5em rgba(0,0,0,0.1);
    width: 80%;
    padding: 1.5em;
}

.auth-logo-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.logo-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 40px;
}

.welcome-section-rp {
@media (max-width: 768px) {
        margin-left: 1em;
        }
}
.welcome {
    padding: 0.75em;
    display: flex;
    color: #92EF8F;
    flex-direction: column;
    padding-left: 3em;
    align-items: flex-start;
}

.welcome h1 {
    font-size: 2.5em;
    color: white;
    margin-bottom: 0.625em;
    font-weight: bold;
    text-align: left;
}

.welcome h1 .highlight {
    color: #92EF8F;
}

.welcome p {
    font-size: 1em;
    color: #FFFFFF;
}

.page-content {
    @media (max-width: 768px) {
        margin-top: 0;

    }
}

.page-content-rp {
    @media (max-width: 768px) {
        margin-top: 0;
    }
}
.footer-text {
    color: white;
    text-align: center;
    width: 100%;
    font-weight: bold;
@media (max-width: 768px) {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 10;
    }
  }

  .footer-link {
    color: white;
    text-decoration: none;
  }

  .footer-link:hover {
    text-decoration: underline;
    color: #92EF8F;
  }

.logo-img {
  height: 10em;
  margin-bottom: 1em;
  max-width: 30em;
  margin-top: 8.5em;

  @media (min-width: 1400px) {
      margin-top: -1em;
  }

  @media (min-width: 1100px) {
      margin-top: -1em;
  }

  @media (max-width: 1200px) {
    height: 8em !important;
    margin-bottom: 0.8em;
  }

  @media (max-width: 992px) {
    height: 6em !important;
    margin-bottom: 0.6em;
  }

  @media (max-width: 768px) {
    height: 4em !important;
    margin-bottom: 0.4em;
  }

  @media (max-width: 576px) {
    height: 3em !important;
    margin-bottom: 0.3em;
  }
}

.signin-other-title {
    display: flex;
    align-items: center;
    justify-content: center;
}

.line {
    flex-grow: 1;
}

.title {
    margin-bottom: 0;
    font-size: 0.875em;
}

.btn-bold {
    font-weight: bold;
    color: #92EF8F;
}

.btn-narrow {
    width: 50%;
    margin: 0 auto;
}

.line-container {
    display: flex;
    justify-content: center;
}

/* Media queries */

/* Desktops large (1200px and below) */
@media (max-width: 75em) { // 1200px - large desktops
    .authentication-page-content {
      width: 80%;
      padding: 0.5rem;
    }

    .logo-img {
      height: 10em;
    }

    .welcome h1 {
      font-size: 2em;
    }
    .logo-img {
      max-width: 16em;
    }
}


@media (max-width: 68em) and (min-width: 62em) { // 1024px to 993px
  .responsive {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .authentication-page-content {
    width: 90%;
    padding: 1.5em;
    margin-top: 1em;
  }

  .welcome-section {
    margin-bottom: 1.5em;
  }

  .welcome h1 {
    font-size: 1.75em;
  }
  
}

/* Desktops and small laptops (992px and below) */
@media (max-width: 62em) { // 992px - small desktops, tablets landscape
    .responsive {
      flex-direction: column;
    }

    .authentication-page-content {
      width: 100%;
      padding: 0.5em;
    }

    .logo-img {
      height: 8em;
    }

    .welcome h1 {
      font-size: 1.75em;
      font-size: 1.75em;
    }
   
}

/* Tablets (768px and below) */
@media (max-width: 48em) { // 768px - tablets portrait
    .responsive {
      flex-direction: column;
    }

/* Tablets (768px and below) */
@media (max-width: 48em) { // 768px - tablets portrait
    .responsive {
      flex-direction: column;
    }

    .authentication-page-content {
      width: 100%;
      padding: 1em;
    }

    .logo-img {
      height: 6em;
    }

    .welcome h1 {
      font-size: 1.5em;
    }

    .welcome p {
      font-size: 0.875em;
    }

    .line {
      margin: 0 0.5em;
    }
    .logo-img {
      margin-top: 4em;
    }
    .logo-section{
      margin-bottom: 1em!important;
    }
    
}

/* Mobile devices (576px and below) */
@media (max-width: 36em) { // 576px - mobile devices
    .authentication-page-content {
      padding: 0.5em;
    }

    .logo-img {
      height: 2em;
    }

    .welcome h1 {
      font-size: 1.25em;
    }

    .welcome p {
      font-size: 0.75em;
    }
    .text-center-newPassword h3{
      // font-size: 1em !important;
      display: none;
    }
    .logo-section {
      margin-top: -4em;
    }
    .welcome {
      margin-top: -1em;
    }
    .card-recoverpw {
      margin-top: -1.5em;
    }
  }
}